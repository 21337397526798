/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql } from "gatsby"
import get from "lodash/get"
import { findBySlug } from "../helpers"
import AdvantagesModule from "../components/advantages-module"
import BenefitsModule from "../components/benefits-module"
import CTAsModule from "../components/ctas-module"
import Hero from "../components/hero"
import Page from "../components/page"
import ProjectGalleryModule from "../components/project-gallery-module"
import ProjectUnique from "../components/project-unique"
import ProcessModule from "../components/process-module"
import TestimonialsModule from "../components/testimonials-module"
import Quote from "../components/design-partner/quote"
import React from "react"

import { paramsCheck } from "../functions/functions"

const Developers = ({ data }) => {
  paramsCheck();
  const [isReady, setIsReady] = React.useState(false)
    const checkIfWindow = () => {
      if (typeof window !== "undefined") {
        return typeof window !== "undefined"
      }
      return typeof window !== "undefined"
    }
  
    React.useEffect(() => {
      if (checkIfWindow) {
        window.location.href = "/"
        setIsReady(true)
      }
    }, [])
  const renderHeroModule = () => {
    const heroBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-hero-module",
    })
    const heroHeights = ["auto", 555]
    if (
      !heroBladePatternData ||
      !get(heroBladePatternData, "desktopImage.file.url")
    ) {
      return
    }

    const ctaList = [
      {
        text: heroBladePatternData.ctaTitle,
        type: "secondary",
        link: heroBladePatternData.ctaDestination || "/",
      },
    ]

    return (
      <Hero
        customStyle={{
          minHeight: heroHeights,
          height: heroHeights,
        }}
        background={{
          type: "image",
          source: {
            desktopImage: heroBladePatternData.desktopImage,
          },
        }}
        title={heroBladePatternData.title}
        body={get(heroBladePatternData, "description.description")}
        ctaList={ctaList}
        type="secondary"
      />
    )
  }
  const renderBenefitsModule = () => {
    const benefitsModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-benefits-module",
    })

    if (!benefitsModuleBladePatternData) return

    return <BenefitsModule bladePatternData={benefitsModuleBladePatternData} />
  }
  const renderServicesModule = () => {
    const servicesModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-services-module",
    })

    if (!servicesModuleBladePatternData) return

    return <ProjectUnique data={servicesModuleBladePatternData} />
  }
  const renderProcessModule = () => {
    const processTextModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-process-text-module",
    })

    if (!processTextModuleBladePatternData) return

    return (
      <ProcessModule bladePatternData={processTextModuleBladePatternData} />
    )
  }
  const renderTestimonialsModule = () => {
    const testimonialsBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-testimonials-module",
    })

    if (!testimonialsBladePatternData) return

    return (
      <TestimonialsModule bladePatternData={testimonialsBladePatternData} />
    )
  }
  const renderAdvantagesModule = () => {
    const advantagesBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-advantages-module",
    })
    if (!advantagesBladePatternData) return
    return <AdvantagesModule bladePatternData={advantagesBladePatternData} />
  }

  const renderCtaModule = () => {
    const ctaModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-cta-module",
    })

    if (!ctaModuleBladePatternData) return

    return (
      <CTAsModule
        textStyle={{ color: theme => theme.colors.white }}
        bladePatternData={ctaModuleBladePatternData}
      />
    )
  }
  const renderProjectGalleryModule = () => {
    const projectGalleryModuleBladePatternData = findBySlug({
      list: data.contentfulPage.bladePatternList,
      slug: "developers-gallery-module",
    })

    if (!projectGalleryModuleBladePatternData) return

    return (
      <ProjectGalleryModule
        bladePatternData={projectGalleryModuleBladePatternData}
        projectsData={data.allContentfulProject}
        customStyle={{ pb: "61%", height: 0 }}
      />
    )
  }

  const renderQuote = () => {
    if (
      !data.allContentfulBladePattern ||
      !data.allContentfulBladePattern.edges.length > 0 ||
      data.allContentfulBladePattern.edges[0].node.quote === null
    )
      return
    else {
      const bladePatternData = data.allContentfulBladePattern.edges[0].node

      return (
        <div
          sx={{
            backgroundColor: theme => theme.colors.backgrounds.primary,
            py: theme => theme.spacing.vertical.lg,
          }}
        >
          {bladePatternData.quote.map(quote => (
            <Quote name={quote.name} quote={quote} photo={quote.image} />
          ))}
        </div>
      )
    }
  }

  return (
    isReady?<Page data={data.contentfulPage}>
      {renderHeroModule()}
      {renderBenefitsModule()}
      {renderServicesModule()}
      {renderProcessModule()}
      {renderProjectGalleryModule()}
      {renderTestimonialsModule()}
      {renderAdvantagesModule()}
      {renderCtaModule()}
      {renderQuote()}
    </Page>:<></>
  )
}

export default Developers

export const query = graphql`
  query developersPageQuery {
    contentfulPage(slug: { eq: "developers" }) {
      ...PageData
      ...BladePatternData
    }

    allContentfulProject(
      filter: { projectTytpe: { in: ["Multifamily"] } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      edges {
        node {
          projectImages {
            id
            title
            fluid(maxWidth: 800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          projectLocation {
            projectLocation
          }
          projectTitle {
            projectTitle
          }
          slug
          projectTytpe
        }
      }
    }
    allContentfulBladePattern(
      filter: { slug: { eq: "developers-quote-module" } }
    ) {
      edges {
        node {
          id
          name
          slug
          quote {
            name
            quote {
              json
            }
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  }
`
